import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './navigation/loader/loader.component';
import { NavbarComponent } from './navigation/navbar/navbar.component';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { LicenseDialogComponent } from './_shared/license-dialog/license-dialog';
import { LicenseMessageComponent } from './_shared/_components/license_message/license-message.component';
import { AuthGuard } from './_shared/_services/auth.guard';
import { HttpInterceptorService } from './_shared/_services/http.interceptor';
import { LicenseService } from './_shared/_services/license.service';
import { LoaderService } from './_shared/_services/loader.service';
import { SessionService } from './_shared/_services/session.service';
import { VersionService } from './_shared/_services/version.service';
import { EndSessionService } from './_shared/_services/end-session.service';
import { SentryErrorHandler } from './_shared/_services/error-handler.service';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ErrorStatusService } from './_shared/_services/error-status.service';
import { HomeComponent } from './home/home.component';
import { CompanyService } from './settings/company/company.service';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        SidenavComponent,
        LoaderComponent,
        LicenseMessageComponent,
        LicenseDialogComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatDialogModule,
        MatToolbarModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatDividerModule,
        MatIconModule,
        MatSnackBarModule,
        MatMenuModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        SessionService,
        AuthGuard,
        LoaderService,
        VersionService,
        LicenseService,
        EndSessionService,
        ErrorStatusService,
        CompanyService,
        { provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        {
            provide: MatDialogRef,
            useValue: null
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2000 } },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
