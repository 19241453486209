import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_shared/_services/auth.guard';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 1, view : 'home'}
  },
  {
    path: 'users/root',
    loadChildren: () => import('./users/admin-login/admin-login.module').then(m => m.AdminLoginModule),
    canActivate: [AuthGuard],
    data: {}
  },
  {
    path: 'users/login',
    loadChildren: () => import('./users/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard],
    data: {}
  },
  {
    path: 'users/associates',
    loadChildren: () => import('./users/associates-login/associates-login.module').then(m => m.AssociatesLoginModule),
    canActivate: [AuthGuard],
    data: {}
  },
  {
    path: 'admin-user',
    loadChildren: () => import('./user-company/user-company.module').then(m => m.UserCompanyModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, adminUser : true, navState: 23 }
  },
  {
    path: 'admin-masters',
    loadChildren: () => import('./user-masters/user-masters.module').then(m => m.UserMastersModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, adminUser : true, navState: 24 }
  },
  {
    path: 'admin-asociates',
    loadChildren: () => import('./user-associates/user-associates.module').then(m => m.UserAssociatesModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, adminUser : true, navState: 25 }
  },
  {
    path: 'user-config',
    loadChildren: () => import('./user-config/user-config.module').then(m => m.UserConfigModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, adminUser : true, navState: 26, }
  },
  {
    path: 'associates-company',
    loadChildren: () => import('./associates-company/associates-company.module').then(m => m.AssociatesCompanyModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, associateAdminUser : true, navState: 27 }
  },
  {
    path: 'master-associates',
    loadChildren: () => import('./master-associates/master-associates.module').then(m => m.MasterAssociatesModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, associateAdminUser : true, master: true, navState: 28 }
  },
  {
    path: 'admin-company',
    loadChildren: () => import('./admin-company/admin-company.module').then(m => m.AdminCompanyModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, companyAdministratorUser : true, navState: 29 }
  },
  {
    path: 'company-users',
    loadChildren: () => import('./company-users/company-users.module').then(m => m.CompanyUsersModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, companyAdministratorUser : true, navState: 30 }
  },
  {
    path: 'company-roles',
    loadChildren: () => import('./company-roles/company-roles.module').then(m => m.CompanyRolesModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, companyAdministratorUser : true, navState: 31 }
  },
  {
    path: 'register',
    loadChildren: () => import('./users/register/register.module').then(m => m.RegisterModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: false }
  },
  {
    path: 'register/confirm',
    loadChildren: () => import('./users/register-confirm/register-confirm.module').then(m => m.RegisterConfirmModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: false }
  },
  {
    path: 'register/password',
    loadChildren: () => import('./users/register-password/register-password.module').then(m => m.RegisterPasswordModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: false }
  },
  {
    path: 'user/new',
    loadChildren: () => import('./users/user-confirm/user-confirm.module').then(m => m.UserConfirmModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: false }
  },
  /* {
    path: 'company',
    loadChildren: () => import('./settings/company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 1 }
  }, */
  {
    path: 'period',
    loadChildren: () => import('./settings/period/period.module').then(m => m.PeriodModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 2, claim: 'claimsCatalogs', view : 'period'}
  },
  {
    path: 'area',
    loadChildren: () => import('./settings/area/area.module').then(m => m.AreaModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 4, claim: 'claimsCatalogs', view : 'area' }
  },
  {
    path: 'department',
    loadChildren: () => import('./settings/department/department.module').then(m => m.DepartmentModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 3, claim: 'claimsCatalogs', view : 'department' }
  },
  {
    path: 'post',
    loadChildren: () => import('./settings/post/post.module').then(m => m.PostModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 5, claim: 'claimsCatalogs', view : 'department' }
  },
  {
    path: 'workcenter',
    loadChildren: () => import('./settings/workcenter/workcenter.module').then(m => m.WorkCenterModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 6, claim: 'claimsCatalogs', view : 'workcenter' }
  },
  {
    path: 'employee',
    loadChildren: () => import('./settings/employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 1, navState: 7, claim: 'claimsCatalogs', view : 'employee' }
  },

  /* {
    path: 'guides',
    loadChildren: () => import('./surveys/guides/guides.module').then(m => m.GuidesRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2 }
  }, */


  {
    path: 'users/account',
    loadChildren: () => import('./users/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 3, claim: 'claimsCompany', view : 'users/account'}
  },

  {
    path: 'survey/:id',
    loadChildren: () => import('./surveys/guides/survey/survey.module').then(m => m.SurveyRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2}
  },
  {
    path: 'section/:id',
    loadChildren: () => import('./surveys/guides/section/section.module').then(m => m.SectionRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2 }
  },
  {
    path: 'cuestion/:id',
    loadChildren: () => import('./surveys/guides/cuestion/cuestion.module').then(m => m.CuestionRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2 }
  },
  {
    path: 'questionnaire/:id',
    loadChildren: () => import('./surveys/guides/questionnaire/questionnaire.module').then(m => m.QuestionnaireRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2, claim: 'claimsQuestionaires', view : 'questionnaire'}
  },


  {
    path: 'resultsats',
    loadChildren: () => import('./surveys/guides/results.ats/results.ats.module').then(m => m.ResultsAtsRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2, claim: 'claimsQuestionaires', view : 'resultsats'}
  },
  {
    path: 'current-state',
    loadChildren: () => import('./surveys/current-state/current-state.module').then(m => m.CurrentStateModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 4, navState: 14, claim: 'claimsTraking', view : 'current-state'}
  },
  {
    path: 'group-policies',
    loadChildren: () => import('./surveys/group-policies/group-policies.module').then(m => m.GroupPoliciesModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 4, navState: 15, claim: 'claimsTraking', view : 'group-policies' }
  },

  {
    path: 'resultsrp',
    loadChildren: () => import('./surveys/guides/results.rp/results.rp.module').then(m => m.ResultsRpRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2, claim: 'claimsQuestionaires', view : 'resultsrp'}
  },

  {
    path: 'resultseo',
    loadChildren: () => import('./surveys/guides/results.eo/results.eo.module').then(m => m.ResultsEoRoutingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 2 , claim: 'claimsQuestionaires', view : 'resultseo'}
  },
  {
    path: 'questionary',
    loadChildren: () => import('./questionary/questionary.module').then(m => m.QuestionaryModule),
    canActivate: [],
    data: { sessionRequired: false }
  },
  {
    path: 'aplication_massive',
    loadChildren: () => import('./aplication-massive/aplication-massive.module').then(m => m.AplicationMassiveModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: false }
  },
  {
    path: 'password/reset',
    loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: false }
  },
  {
    path: 'password/request',
    loadChildren: () => import('./request-reset/request-reset.module').then(m => m.RequestResetModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: false }
  },
  {
    path: 'tracking/:id/:embebed',
    loadChildren: () => import('./surveys/tracking/tracking.module').then(m => m.TrackingModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 4, claim: 'claimsTraking', view : 'tracking'},
  },
  {
    path: 'group-state/:id/:survey/:period/:embebed',
    loadChildren: () => import('./surveys/group-state/group-state.module').then(m => m.GropuStateModule),
    canActivate: [AuthGuard],
    data: { sessionRequired: true, topNavbarState: 4, claim: 'claimsTraking', view : 'group-state'}
  },
  {
    path: '',
    redirectTo: '/users/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    loadChildren: () => import('./users/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , onSameUrlNavigation : 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
