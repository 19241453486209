<button class="close-button" *ngIf="!data.noCancel" mat-fab color="warn" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
</button>

<button class="admin-button" *ngIf="data.adminOption" mat-mini-fab color="primary" (click)="close(-1)"
    [matTooltip]="'Ingresar como Administrador'" matTooltipPosition="after" matTooltipClass="custom-tooltip">
    <mat-icon>settings</mat-icon>
</button>
<div class="all-container">
    <div class="form-container" *ngIf="form">

        <div class="form-body" [formGroup]="form" (keyup.enter)="form.invalid || data.blockEnter || saveData()">
            <div [ngClass]="{'centered': data.isSmall || data.nameCentered}" class="catalog-title modal-name">
                <h2>{{data.name}}</h2>
                <div class="f-separator"></div>
            </div>
            <div *ngIf="data.message" style="margin: 0 20px;">
                <h4 class="description-message" style="margin: 0;">{{data.message}}</h4>
            </div>
            <div *ngIf="data.multipleMessages" style="flex: 1 0 100%;">
                <h4 *ngFor="let m of data.multipleMessages">
                    <ng-container *ngIf="!m.link">
                        {{m.message}}
                    </ng-container>
                    <ng-container *ngIf="m.link">
                        ***Para más detalles, consulte nuestra <a [href]="m.link" target="_blank" style="color: var(--primary-color);">{{m.message}}</a>
                    </ng-container>
                </h4>
            </div>
            <ng-container *ngFor="let control of getControls(); let i = index" class="form-section"
                [ngSwitch]="control.controlType">

                <h4 class="separator" *ngIf="control.separator" style="flex: 1 0 100%;">{{control.separator}}</h4>

                <div *ngSwitchCase="'text'" style="display: flex;">
                    <div style="flex: 1;">
                        <mat-form-field>
                            <input matInput type="text"
                                [placeholder]="isRequired(control) ? '* ' + control.placeholder : control.placeholder"
                                [formControlName]="control.name" cdkFocusInitial
                                (change)="(control.onChange) ? control.onChange(form, $event, dialog) : null;"
                                [style]="control.style">
                        </mat-form-field>
                        <app-form-errors class="not-move" [control]="control"></app-form-errors>
                    </div>

                    <button style="margin: 0 10px;" mat-icon-button color="primary" *ngIf="helpActions[control.name]"
                        (click)="helpActions[control.name](control, form)">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>
                <div *ngSwitchCase="'password'">
                    <mat-form-field>
                        <input matInput [type]="control.hide ? 'text' : 'password'"
                            [placeholder]="isRequired(control) ? '* ' + control.placeholder : control.placeholder"
                            [formControlName]="control.name" cdkFocusInitial>
                        <button mat-icon-button matSuffix (click)="control.hide = !control.hide"
                            style="margin: 0px !important">
                            <mat-icon color="primary">{{control.hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                    <app-form-errors class="not-move" [control]="control"></app-form-errors>
                </div>
                <div *ngSwitchCase="'number'" style="display: flex;">
                    <div style="flex: 1;">
                        <mat-form-field>
                            <input matInput type="number"
                                [placeholder]="isRequired(control) ? '* ' + control.placeholder : control.placeholder"
                                [formControlName]="control.name" [readonly]="control.nonEditable"
                                (change)="(control.onChange) ? control.onChange(form,$event, dialog) : null; ">
                        </mat-form-field>
                        <app-form-errors class="not-move" [control]="control"></app-form-errors>
                    </div>


                    <button style="margin: 0 10px;" mat-icon-button color="primary" *ngIf="helpActions[control.name]"
                        (click)="helpActions[control.name](control, form)">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>
                <div *ngSwitchCase="'checkbox'" [ngClass]="{'center': data.noCancel || data.noAccept}" class="center"
                    [style]="control.style">

                    <div style="display: flex;flex-flow: row; align-items: center;"
                        *ngIf="control.placeholder != 'TERM_COND' && control.placeholder != 'QUEST1' && control.placeholder != 'QUEST2'">
                        <mat-checkbox color="primary" [formControlName]="control.name"
                            [labelPosition]="(control?.checkBoxConfig) ? control?.checkBoxConfig?.orientation : 'before'"
                            (change)="validInput(control, form); (control.onChange) ? control.onChange(form,$event, dialog) : null;">
                            {{isRequired(control) ? '* ' + control.placeholder : control.placeholder}}</mat-checkbox>
                        <app-form-errors class="not-move" [control]="control"></app-form-errors>

                        <button style="margin: 0 10px;" mat-icon-button color="primary"
                            *ngIf="helpActions[control.name]" (click)="helpActions[control.name](control)">
                            <mat-icon>help</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="control.placeholder == 'QUEST1'">
                        <mat-checkbox color="primary" [formControlName]="control.name" [labelPosition]="'after'">
                            Estoy de acuerdo con el <a class="link"
                                href="https://evaluathec.com/aviso_de_privacidad.html" target="_blank">Aviso de
                                Privacidad</a>
                        </mat-checkbox>

                        <app-form-errors class="not-move" [control]="control"></app-form-errors>
                    </div>

                    <div *ngIf="control.placeholder == 'QUEST2'" style="display: flex; align-items: baseline;">

                        <mat-checkbox color="primary" [formControlName]="control.name" [labelPosition]="'after'">
                        </mat-checkbox>
                        <span style="text-align: justify; margin-left: 20px;">
                            Conozco las instrucciones, entiendo que debo estar concentrado para contestar este
                            cuestionario y
                            manifiesto bajo protesta de decir verdad que todas las respuestas que proporcione son
                            verídicas
                            y pueden ser comprobadas con fundamentos concretos, y estoy conforme el <a class="link"
                                href="https://evaluathec.com/aviso_de_privacidad.html" target="_blank">Aviso de
                                Privacidad</a>
                        </span>

                        <app-form-errors class="not-move" [control]="control"></app-form-errors>
                    </div>

                    <div *ngIf="control.placeholder == 'TERM_COND'">
                        <mat-checkbox color="primary" [formControlName]="control.name" [labelPosition]="'after'">
                            Acepto los <a class="link" href="https://evaluathec.com/terminos_y_condiciones.html"
                                target="_blank">Términos y Condiciones</a>
                        </mat-checkbox>
                        <app-form-errors class="not-move" [control]="control"></app-form-errors>
                    </div>
                </div>
                <div *ngSwitchCase="'select'" style="display: flex;width: 100%;">
                    <div style="flex:1;">
                        <mat-form-field>
                            <mat-label>{{isRequired(control) ? '* ' + control.placeholder : control.placeholder}}
                            </mat-label>
                            <mat-select [formControlName]="control.name"
                                (selectionChange)="(control.onChange) ? control.onChange(form,$event, dialog, control?.selectConfig?.data) : null; ">
                                <mat-option *ngFor="let o of control?.selectConfig?.data"
                                    [value]="o[control?.selectConfig?.value]?.value ? o[control?.selectConfig?.value]?.value : o[control?.selectConfig?.value] ">
                                    {{ o[control?.selectConfig?.name]?.value ? o[control?.selectConfig?.name]?.value : o[control?.selectConfig?.name]}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-form-errors class="not-move" [control]="control"></app-form-errors>

                    </div>

                    <button mat-mini-fab color="primary" *ngIf="selectActions[control.name]"
                        (click)="selectActions[control.name](control)">
                        <mat-icon>add</mat-icon>
                    </button>

                    <button style="margin: 0 10px;" mat-icon-button color="primary" *ngIf="helpActions[control.name]"
                        (click)="helpActions[control.name](control)">
                        <mat-icon>help</mat-icon>
                    </button>

                </div>
                <div *ngSwitchCase="'multiple-select'" style="display: flex;width: 100%;">
                    <div style="flex:1;">
                        <mat-form-field>
                            <mat-label>{{isRequired(control) ? '* ' + control.placeholder : control.placeholder}}
                            </mat-label>
                            <mat-select [formControlName]="control.name"
                                (selectionChange)="(control.onChange) 
                                ? saveLastValue( control.onChange(form, $event, dialog, control?.selectConfig?.data, control?.lastValue), control , $event) 
                                : null"
                                multiple>
                                <mat-option *ngFor="let o of control?.selectConfig?.data"
                                    [value]="o[control?.selectConfig?.value]?.value ? o[control?.selectConfig?.value]?.value : o[control?.selectConfig?.value] ">
                                    {{ o[control?.selectConfig?.name]?.value ? o[control?.selectConfig?.name]?.value : o[control?.selectConfig?.name]}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-form-errors class="not-move" [control]="control"></app-form-errors>

                    </div>

                    <button mat-mini-fab color="primary" *ngIf="selectActions[control.name]"
                        (click)="selectActions[control.name](control)">
                        <mat-icon>add</mat-icon>
                    </button>

                </div>
                <div *ngSwitchCase="'text-area'" style="flex:1 0 500px">
                    <mat-form-field>
                        <textarea matInput type="text"
                            [placeholder]="isRequired(control) ? '* ' + control.placeholder : control.placeholder"
                            [formControlName]="control.name"
                            style="height: 100px; resize: none;"
                            >
                        </textarea>
                    </mat-form-field>
                    <app-form-errors class="not-move" [control]="control"></app-form-errors>
                </div>
                <div *ngSwitchCase="'date'">
                    <mat-form-field>
                        <input matInput [min]="minDate" [matDatepicker]="picker"
                            [placeholder]="isRequired(control) ? '* ' + control.placeholder : control.placeholder"
                            [formControlName]="control.name" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>


                    <app-form-errors class="not-move" [control]="control"></app-form-errors>
                </div>
                <div *ngSwitchCase="'file'">
                    <div class="file-field">
                        <div class="file-settings">
                            <h4 style="margin: 0 0 5px 0;">
                                {{isRequired(control) ? '* ' + control.placeholder : control.placeholder}}</h4>
                            <h5 *ngIf="control?.fileConfig?.uploadLimit">*Peso máximo:
                                {{control?.fileConfig?.uploadLimit}} MB</h5>
                            <h5 *ngFor="let r of control?.fileConfig?.extraSettings">{{r}}</h5>
                        </div>

                        <div class="file-button">
                            <input id="file" type="file" name="file" (change)="setFile($event, control)" #fileInput
                                [accept]="control?.fileConfig?.accept">
                            <button mat-raised-button color="primary" (click)="fileInput.click()">
                                {{(!control.value) ? 'Examinar...' : 'Archivo: ' + control.value.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="template-container" *ngIf="getTemplate()">
                <h4 *ngIf="data.templateTitle">{{data.templateTitle}}</h4>
                <ng-container style="width: -webkit-fill-available;" *ngTemplateOutlet="getTemplate()"></ng-container>
            </div>
        </div>

        <section class="buttons-container" *ngIf="!data.noButtons">
            <button mat-raised-button color="white" (click)="close()" *ngIf="!data.noCancel">Cancelar</button>
            <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="saveData()"
                *ngIf="!data.noAccept">Aceptar</button>
        </section>

        <section class="buttons-container" *ngIf="data.buttonOptions">
            <button *ngFor="let b of data.buttonOptions" mat-raised-button [color]="b.type"
                (click)="saveData(b.value)">{{b.placeholder}}</button>
        </section>
    </div>
    <div class="form-container" *ngIf="!form && !data.file && !data.fileAsHtml">
        <div class="catalog-title">
            <h2 style="text-align: center; width: 100%;">{{data.name}}</h2>
            <div class="f-separator"></div>
        </div>

        <div class="template-container" *ngIf="getTemplate()">
            <h4 *ngIf="data.templateTitle">{{data.templateTitle}}</h4>
            <ng-container style="width: -webkit-fill-available;" *ngTemplateOutlet="getTemplate()"></ng-container>
        </div>

        <div class="message" *ngIf="data.message && data.message.message">
            <ng-container *ngFor="let msg of toArray(data.message.message)">
                {{msg}} <br *ngIf="toArray(data.message.message).length > 1">
            </ng-container>
            <br>
            
            
            Se {{data.message.action}} {{data.message.info.length}} registros
        </div>
        <div class="message" *ngIf="data.message && !data.message.message" [ngStyle]="data.messageStyles">
            
            <ng-container *ngFor="let msg of toArray(data.message)">
                {{msg}} <br *ngIf="toArray(data.message).length > 1">
            </ng-container>
            <div *ngIf="data.extraMessages" style="display: flex;  flex-flow: column; margin-top: 10px;">
                <ng-container *ngFor="let m of data.extraMessages">
                    <a [href]="m.value" target="_blank" *ngIf="m.isUrl" style="margin-top: 10px;">{{m.placeholder}}</a>
                    <div *ngIf="!m.isUrl" style="margin-top: 10px;">{{m.value}}</div>
                </ng-container>
            </div>
            <div *ngIf="data.errors">
                <ng-container *ngFor="let e of data.errors">
                    <br><span class="error">
                        -{{e}}-
                    </span>
                </ng-container>
            </div>
            <div *ngIf="data.statusCode">
                <h5 class="status-code">Código: {{data.statusCode}}</h5>
            </div>
        </div>

        <section class="buttons-container" *ngIf="!data.loginOptions">
            <ng-container *ngIf="!data.buttonOptions && !data.loginOptions">
                <button mat-raised-button color="white" (click)="close()"
                    *ngIf="!data.noCancel && !data.noButtonCancel">Cancelar </button>
                <button mat-raised-button color="primary" (click)="closeWindow()"
                    *ngIf="data.closeWindow">Aceptar</button>
                <button mat-raised-button color="primary" (click)="confirmDelete()"
                    *ngIf="!data.noAccept">Aceptar</button>
            </ng-container>
            <ng-container *ngIf="data.buttonOptions">
                <button *ngFor="let b of data.buttonOptions" mat-raised-button [color]="b.type"
                    (click)="close(b.value)">{{b.placeholder}}</button>
            </ng-container>
        </section>
        <section class="login-options" *ngIf="data.loginOptions">
            <div *ngFor="let b of data.loginOptions" (click)="close(b.value)">
                <button mat-mini-fab [color]="b.type"
                    style="margin: 0px 20px;">{{b.placeholder[0] | uppercase }}</button>

                <h4 style="flex: 2; text-align: center;"><b>{{b.placeholder}}</b></h4>
            </div>
        </section>
    </div>
    <div class="form-container" *ngIf="!form && data.file" style="align-items: center;">
        <iframe [src]="data.file" type="application/pdf" width="100%" height="100%" style="overflow: auto;">
        </iframe>

        <a style="width: fit-content;" mat-raised-button color="primary" [href]="data.file"
            [download]="data.fileName">Descargar</a>
    </div>
    <!-- <div *ngIf="!form && data.fileAsHtml" style="align-items: center;">
        <div [innerHTML]="data.fileAsHtml" style="overflow: auto; padding: 15px 20px;" id="htmlToFile">
        </div>
        <button style="width: fit-content;
        position: absolute;
        bottom: 50px;
        left: 30px;
        background: black;
        color: white;" color="primary" (click)="htmlToPdf()" mat-button>Descargar</button>
    </div> -->
    
</div>