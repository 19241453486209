import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { Session } from 'src/app/_shared/_models/session.model';
import { LicenseService } from 'src/app/_shared/_services/license.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';
import { SessionService } from 'src/app/_shared/_services/session.service';
import { FormDialogComponent } from 'src/app/_shared/_components/form-dialog/form-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyService } from 'src/app/settings/company/company.service';



@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    typeUser

    @Output()
    onMenu: EventEmitter<any> = new EventEmitter<any>();

    session: Observable<Session>

    topNavbarState: Observable<number>

    smallScreen: Observable<boolean>

    constructor(
        private sessionService: SessionService,
        private route: Router,
        private loaderService: LoaderService,
        private licenseService: LicenseService,
        private dialog: MatDialog,
        private router: Router,
        private companyService: CompanyService
    ) {
        this.session = this.sessionService._session;
        //S = superuser
        this.session.subscribe((s) => {
            if (s) {
                this.typeUser = this.decodeToken(s.token).LicenseType;
                if (this.decodeToken(s.token).AdminOptions == 'True' && this.typeUser != 'S') {
                    this.typeUser = 'AU';
                }
            }
        })
        this.getSessionInfo()
    }

    async getSessionInfo() {
        var s = this.sessionService.getSession();
        if (s && s.type == "A") {
            let res = await this.licenseService.getLicense().toPromise()
            this.licenseService.setLicenseState(res.finishLicenseDate, res.associatedID);
            this.licenseService.setLicense(res);
        }
        this.topNavbarState = this.sessionService._topNavbarState;
    }

    ngOnInit(): void { }

    logout() {
        this.loaderService.show();
        this.licenseService.setLicenseState(-1);
        this.sessionService.logout();

        if (this.typeUser == 'S')
            this.route.navigate(['/users/root']);
        else if (this.typeUser == 'T')
            this.route.navigate(['/users/associates']);
        else
            this.route.navigate(['/users/login']);
        this.loaderService.hide();

    }

    async loginCompany() {

        var token = this.sessionService.getSession().token;
        //var companies: any = (await this.companyService.getAll().toPromise()).items

        var info = this.sessionService.decodeAndReturn(token);
        var companies : any = JSON.parse(info.Companies);

        var sessionData = {
            name: "Inicio de Sesión",
            message: "Seleccione la empresa deseada: ",
            messageStyles: {
                'margin': '0'
            },
            loginOptions: [
                //...companies.map(c => { return { placeholder: c._businessName, value: c._companyID, type: 'primary' } }),
                ...companies.map(c => { return { placeholder : c.Name, value: c.CompanyID, type: 'primary' } }),
            ]
        }

        this.loaderService.hide();
        var company = await this.dialog.open(FormDialogComponent, { data: sessionData, panelClass: 'panel-nooverflow' }).afterClosed().toPromise()
        this.loaderService.show();

        if (!company) {
            this.loaderService.hide()
            return
        }

        var ses = await this.sessionService.loginCompany(company, token).toPromise()

        //Insert session data
        this.sessionService.login(ses[1].token, ses[0].token);

        await this.getSessionInfo()

        this.router.navigate(['/home'])

        this.loaderService.hide()
    }

    async loginOptions() {

        var token = this.sessionService.getAdminToken();
        /* var session = this.sessionService.getSession();
        var isAdmin = session.isAdmin == 'true'; */

        var adminSession = this.sessionService.decodeAndReturn(token);
        var isAdmin = adminSession.AdminOptions == 'True';

        var info = this.sessionService.decodeAndReturn(token);
        var companies : any = JSON.parse(info.Companies);

        var sessionData = {
            name: "Cambiar de Empresa",
            message: "Seleccione la empresa deseada: ",
            messageStyles: {
                'margin': '0'
            },
            loginOptions: [
                ...companies.map(c => { return { placeholder : c.Name, value: c.CompanyID, type: 'primary'} } ),
            ],
        }

        if(isAdmin){
            sessionData['adminOption'] = true
        }
        
        this.loaderService.hide();
        var company = await this.dialog.open(FormDialogComponent, { data: sessionData, panelClass: 'panel-nooverflow' }).afterClosed().toPromise()
        this.loaderService.show();

        if (!company) {
            this.loaderService.hide()
            return
        }else if(company != -1)
        {
            var ses = await this.sessionService.loginCompany(company, token).toPromise()

            //Insert session data
            this.sessionService.login(ses[1].token, ses[0].token); 
            await this.getSessionInfo()
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.navigate(['/home'])
        }
        else{
            this.licenseService.setLicenseState(-1);
            this.licenseService.setLicense(null);

            this.sessionService.login(token);
            this.router.navigate(['/admin-company'])
        }

        this.loaderService.hide()
    }

    decodeToken(token: string) {
        var decoded: any = jwt_decode(token);
        if (!decoded)
            return null;
        return decoded;
    }
}
